import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import AmplitudeConfig from './util/logging/amplitude-config';

const theme = extendTheme({
  colors: {
    brand: {
      50: '#e0f6ff',
      100: '#bbdff5',
      200: '#94c8e9',
      300: '#6cb1df',
      400: '#459bd5',
      500: '#2d82bc',
      600: '#206593',
      700: '#14486a',
      800: '#052b42',
      900: '#000f1b',
    },
    blue: {
      50: '#f5fcff',
    },
    gray: {
      100: '#f7fafc',
    },
  },
  styles: {
    global: {
      '.zsiq_theme1.zsiq_floatmain': {
        base: {
          left: '-200px !important', // hack to hide the chat widget and avoid the complicated conditional logic
        },
        lg: {
          right: '10px !important',
          left: 'auto !important',
        },
      },
    },
  },
});

const pages = import.meta.glob('./pages/**/*.tsx');

function resolvePageComponent(name: string) {
  for (const path in pages) {
    const fileName = path.replace('./pages/', '');
    if (fileName === `${name.replace('.', '/')}.tsx`) {
      return typeof pages[path] === 'function' ? pages[path]() : pages[path];
    }
  }

  throw new Error(`Page not found: ${name}`);
}

createInertiaApp({
  resolve: name => resolvePageComponent(name),
  setup({ el, App, props }) {
    AmplitudeConfig();
    createRoot(el).render(
      <ChakraProvider theme={theme}>
        <App {...props} />
      </ChakraProvider>,
    );
  },
  progress: {
    color: '#FCAE1E',
  },
});
