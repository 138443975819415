import { AmplitudeTrackingEvents, AmplitudeEventProperties } from '@/types';
import { amplitudeDeviceId } from '@/util/app-config';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

/**
 * @see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#custom-user-id
 */
export const setAmplitudeUserId = (userId: string | undefined) => {
  amplitude.setUserId(userId);
};

export const setUpAccountLevelReporting = (
  groupType: string,
  groupName: string,
) => {
  amplitude.setGroup(groupType, groupName);
};

/**
 * @see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#user-properties
 */
export const setAmplitudeUserProperties = (
  userProperties: Record<string, string>,
) => {
  const identifyEvent = new amplitude.Identify();

  Object.entries(userProperties).forEach(([key, value]) => {
    identifyEvent.set(key, value);
  });

  amplitude.identify(identifyEvent);
};

/**
 * @see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#tracking-an-event
 */
export const trackEvent = (
  eventName: AmplitudeTrackingEvents,
  eventProperties?: AmplitudeEventProperties,
) => {
  amplitude.track(eventName, eventProperties);
};

/**
 * @see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#reset-when-user-logs-out
 */
export const resetAmplitude = () => {
  amplitude.reset();
};

/**
 * @see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#initialize-the-sdk
 */
export default function AmplitudeConfig() {
  const key = import.meta.env.VITE_AMPLITUDE_API_KEY;

  amplitude.init(key, undefined, {
    minIdLength: 1,
    defaultTracking: {
      attribution: true,
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
    ...(amplitudeDeviceId ? { deviceId: amplitudeDeviceId } : {}),
  });

  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 0.01,
  });

  amplitude.add(sessionReplayTracking);

  return amplitude;
}
